import loadable from '@loadable/component'
import Header from 'components/Header'
import Modals from 'components/Modals'
import NoTransactionsBanner from 'components/NoTransactionsBanner'
// import StakeBoosterBanner from 'parts/common/StakeBoosterBanner'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import { handleOpenUrl } from 'utils'

const HomeRoute = loadable(() => import('./pages/Home'))
const StakingRoute = loadable(() => import('./parts/staking'))
const DashboardRoute = loadable(() => import('./pages/Dashboard'))
const ProjectRoute = loadable(() => import('./parts/ido'))

// const stakeBoosterUrl =
//   'https://medium.com/solanaprime/double-your-prime-stake-with-the-early-contributor-staking-booster-bf2700835756'

export default function App() {
  return (
    <BrowserRouter>
      <NoTransactionsBanner />
      <Header />
      {/* <StakeBoosterBanner onClick={handleOpenUrl(stakeBoosterUrl)} /> */}
      <Routes>
        <Route index element={<HomeRoute />} />
        <Route path="project/:id" element={<ProjectRoute />} />
        <Route path="staking" element={<StakingRoute />} />
        <Route path="dashboard" element={<DashboardRoute />} />
      </Routes>
      <Modals />
      <Toaster position="bottom-right" reverseOrder={false} />
    </BrowserRouter>
  )
}
