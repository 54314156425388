import { ModalHeading, TextLink } from 'components'
import Button from 'components/Button'
import { useStore } from 'effector-react'
import { ReactComponent as CheckIcon } from 'icons/check.svg'
import { $txHash, closeModal } from 'models/modal'
import { explorerTxUrl, t } from 'utils'

export default function TxSuccessModal() {
  const txHash = useStore($txHash)
  const url = explorerTxUrl(txHash)

  const handleClick = () => {
    closeModal()
  }

  return (
    <div className="flex flex-grow flex-col">
      <ModalHeading>{t('modal_tx_success_heading')}</ModalHeading>
      <div className="flex justify-center">
        <div className="my-8">
          <CheckIcon width="3.5em" height="3.5em" />
        </div>
      </div>
      <div className="mb-[1.77rem] flex justify-center rounded-base bg-pureBlack/[0.07] p-[1.11rem]">
        <TextLink className="text-center font-mono text-[0.77rem]" href={url}>
          View transaction on explorer
        </TextLink>
      </div>
      <Button className="w-full" size="l" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
