import { sample } from 'effector'
import {
  $generalInfo,
  $isResident,
  $mobileMenuState,
  $session,
  fetchGeneralInfo,
  fetchGeneralInfoFx,
  fetchGeneralInfoWithKYCFx,
  setIsResident,
  toggleMenu,
} from '.'
import { disconnectWallet } from 'models/wallet'

$mobileMenuState.on(toggleMenu, (st) => !st)

$isResident.on(setIsResident, () => true)

sample({
  clock: fetchGeneralInfoFx.doneData,
  target: $generalInfo,
})

// sample({
//   clock: fetchGeneralInfoWithKYCFx.failData,
//   filter: (err) => Boolean(err.message.match(/session/i)),
//   target: disconnectWallet,
// })

sample({
  clock: fetchGeneralInfoWithKYCFx.doneData,
  fn: ({ pools, stakingInfo }) => ({ pools, stakingInfo }),
  target: $generalInfo,
})

sample({
  clock: fetchGeneralInfo,
  source: $session,
  filter: Boolean,
  target: fetchGeneralInfoWithKYCFx,
})

sample({
  clock: fetchGeneralInfo,
  source: $session,
  filter: (session) => !session,
  target: fetchGeneralInfoFx,
})

fetchGeneralInfo()
setInterval(fetchGeneralInfo, 5000)
