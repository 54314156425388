import { useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Button from './Button'

export default function NoTransactionsBanner() {
  const [open, setOpen] = useState(false)
  let [disabled, setDisabled] = useState(true)

  const { pathname } = useLocation()

  useLayoutEffect(() => {
    const show = process.env.REACT_APP_SHOW_BANNER ?? ''

    let id: any
    id = setTimeout(() => {
      setDisabled(false)
    }, 5000)

    if (!localStorage.getItem('no_transactions_banner') && show === 'true') {
      setOpen(true)
    } else {
      setOpen(false)
    }

    return () => {
      clearTimeout(id)
    }
  }, [])

  const handleCloseClick = () => {
    localStorage.setItem('no_transactions_banner', 'true')
    setOpen(false)
  }

  if (!open || pathname !== '/staking') {
    return null
  }

  return (
    <div className="fixed z-[100] h-screen w-screen overflow-hidden bg-[#252323]/50 backdrop-blur-3xl">
      <div className="absolute top-0 hidden w-full bg-linesStakingInfo bg-repeat-x dxs:block dxs:h-[14px] ds:h-[17px] dm:h-[19px] dl:h-[21.5px] dxl:h-[26px]"></div>
      <div className="absolute bottom-0 hidden w-full bg-linesStakingInfo bg-repeat-x dxs:block dxs:h-[14px] ds:h-[17px] dm:h-[19px] dl:h-[21.5px] dxl:h-[26px]"></div>
      <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col justify-center text-center">
        <div
          className="mb-5 whitespace-nowrap font-pressStart2P text-[30px] uppercase text-blue 
          t:text-[50px]  dxs:text-[60px] dl:text-[70px]"
        >
          Solanaprime is <br /> under upgrade
        </div>
        <div
          className="w-[70%] self-center text-center text-xl leading-[120%]
          text-white/60 dxs:text-[26px] dl:text-3xl
          "
        >
          Please, do not make any transactions. If you stake, unstake, or claim
          during the upgrade, it may cause errors and loss of funds
        </div>
        <Button
          disabled={disabled}
          color="red"
          onClick={handleCloseClick}
          className="mt-10 w-fit self-center px-8 py-4 text-base
          t:text-lg dxs:text-xl
          "
        >
          I understand
        </Button>
      </div>
    </div>
  )
}
