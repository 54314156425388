import TxToast from 'components/TxToast'
import { sample } from 'effector'
import { sendTxFx } from 'models/wallet'
import toast from 'react-hot-toast'
import { sleep } from 'utils'
import { $transactions, getTxFx, pushTx, Tx } from '.'

const maxRetries = 20

$transactions.on(pushTx, (transactions, tx) => {
  const existingTx = transactions.find((t) => t.txHash === tx.txHash)
  if (!existingTx) {
    toast.custom((t) => <TxToast t={t} txHash={tx.txHash} />, {
      duration: 60 * 60 * 1000,
    })
    return [...transactions, tx]
  }

  return tx.retries < maxRetries
    ? [
        ...transactions.filter((t) => t.txHash !== tx.txHash),
        { ...tx, retries: tx.retries + 1 },
      ]
    : transactions.filter((t) => t.txHash === tx.txHash)
})

sample({
  source: pushTx,
  filter: (tx) => tx.status === 'pending',
  target: getTxFx,
})

sample({
  clock: getTxFx.fail,
  fn: (f) => f.params,
  target: pushTx,
})

getTxFx.done.watch(({ params, result }) => {
  if (!result) {
    sleep(5000).then(() => pushTx(params))
    return
  }

  pushTx({ ...params, status: !result.meta?.err ? 'successful' : 'failed' })
})

sample({
  source: sendTxFx.doneData,
  fn: (txHash) => ({ txHash, retries: 0, status: 'pending' } as Tx),
  target: pushTx,
})
