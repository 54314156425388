import { ModalHeading, TextLink } from 'components'
import Button from 'components/Button'
import Block from 'components/WalletBlock'
import WalletKyc from 'components/WalletKyc'
import TextEntry from 'components/WalletTextEntry'
import { useStore } from 'effector-react'
import { closeModal } from 'models/modal'
import { $balanceFetched, $stakingInfo, $walletBalance } from 'models/staking'
import { $address, $walletName, disconnectWallet } from 'models/wallet'
import { wallets } from 'models/wallet/wallets'
import TierName from 'parts/common/TierName'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { explorerAddressUrl, t } from 'utils'
import { formatNumber, toDecimal } from 'utils/numbers'

export default function ConnectWalletSuccessModal() {
  const TABS = ['Account']
  const showKyc = process.env.REACT_APP_SHOW_KYC === 'true'
  if (showKyc) {
    TABS.push()
  }

  const address = useStore($address)
  const walletBalance = useStore($walletBalance)
  const walletName = useStore($walletName)
  const stakingInfo = useStore($stakingInfo)
  const balanceFetched = useStore($balanceFetched)
  const navigate = useNavigate()

  const [currentTab, setCurrentTab] = useState(TABS[0])

  const userTier = 6 - (stakingInfo?.account?.tier?.level ?? 0)

  const { robot } = React.useMemo(() => {
    switch (userTier) {
      case 1:
        return { robot: 'bg-robot1' }
      case 2:
        return { robot: 'bg-robot2' }
      case 3:
        return { robot: 'bg-robot3' }
      case 4:
        return { robot: 'bg-robot4' }
      case 5:
        return { robot: 'bg-robot5' }
      default:
        return { robot: 'bg-robotnone' }
    }
  }, [userTier])

  const shortAddress = `${address.slice(0, 4)}..${address.slice(40, 44)}`

  const handleGoToStakingClick = () => {
    navigate('/staking')
    closeModal()
  }

  const handleDisconnectClick = () => {
    disconnectWallet()
  }

  const wallet = wallets.find(({ name }) => name === walletName) || wallets[0]

  return (
    <div className="flex flex-grow flex-col">
      <ModalHeading>{t('wallet')}</ModalHeading>
      <div className="mt-4 rounded-base bg-yellowDark p-[0.66rem] mm:p-[1.1rem]">
        <div className="growoutline-none flex w-full items-center justify-between">
          <div className="flex items-center text-black">
            <p className="mr-2 hidden text-[1.16rem] font-bold ml:block">
              {wallet.name}
            </p>
            <TextLink href={explorerAddressUrl(address)}>
              {shortAddress}
            </TextLink>
          </div>
          <div className="flex items-center">
            <p
              className="mr-2 cursor-pointer text-[0.88rem] underline hover:text-black/60"
              onClick={handleDisconnectClick}
            >
              Disconnect
            </p>
            <div className="rounded-base bg-pureBlack/[0.12] p-1">
              <img
                className="h-[1.1rem] w-[1.1rem]"
                src={wallet.icon}
                alt={wallet.name}
              />
            </div>
          </div>
        </div>
        <div className="mt-[0.88rem] flex ml:hidden">
          {TABS.map((tabName) => (
            <div
              key={tabName}
              onClick={() => {
                setCurrentTab(tabName)
              }}
              className={`
                  ${tabName === currentTab ? 'bg-black/10' : ''}
                  cursor-pointer rounded-t-base p-[0.66rem] font-mono text-[0.88rem] 
                `}
            >
              {tabName}
            </div>
          ))}
        </div>
        <div className="space-y-[0.88rem] ml:mt-[0.88rem]">
          <div
            className={`${
              currentTab === TABS[0] ? 'block' : 'hidden ml:block'
            }`}
          >
            <Block
              img={
                <div
                  className={`absolute bottom-0 hidden min-w-[40%] ml:block ${robot} h-[90%] bg-120% bg-top bg-no-repeat`}
                ></div>
              }
            >
              <TextEntry label="Wallet balance">
                {balanceFetched
                  ? formatNumber(walletBalance, { detailed: true })
                  : '...'}{' '}
                PRIME
              </TextEntry>
              <TextEntry label="Your current stake">
                {formatNumber(toDecimal(stakingInfo?.account?.stakingBalance), {
                  detailed: true,
                })}{' '}
                PRIME
              </TextEntry>
              <TextEntry label="Your current tier">
                <TierName tier={userTier} />
              </TextEntry>
              {userTier === 0 ? (
                <Button
                  className="w-full uppercase"
                  onClick={handleGoToStakingClick}
                >
                  Go to staking
                </Button>
              ) : null}
            </Block>
          </div>
          {showKyc && (
            <div
              className={`${
                currentTab === TABS[1]
                  ? '!m-0 block ml:!mt-[0.88rem]'
                  : 'hidden ml:block'
              }`}
            >
              <WalletKyc />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
