import React from 'react'

interface Props {
  children?: React.ReactNode
  disabled?: boolean
  onClick?: () => void
  className?: string
}

export default function FormButton({
  children,
  disabled = false,
  onClick = () => {},
  className = '',
}: Props) {
  const cn = {
    base: 'border border-border/0 transition outline-none focus:outline-none uppercase whitespace-nowrap w-full leading-none rounded-base text-yellow font-bold',
    primaryBlue: 'bg-blue hover:bg-blueHover active:bg-blueActive',
    sizeBased: 'text-[1.17rem] p-[1.1rem]',
    disabled:
      'disabled:bg-transparent disabled:text-black disabled:border disabled:border-border disabled:pointer-events-none',
  }

  return (
    <button
      className={`${cn.base} ${cn.disabled} ${cn.primaryBlue} ${cn.sizeBased} ${className}`}
      disabled={disabled}
      type="button"
      onClick={() => onClick()}
    >
      {children}
    </button>
  )
}
