import { useStore } from 'effector-react'
import { $modalState, ModalState } from 'models/modal'
import AboutKYCModal from './AboutKYCModal'
import ConnectWalletModal from './ConnectWalletModal'
import ConnectWalletSuccessModal from './ConnectWalletSuccessModal'
import ModalContainer from './ModalContainer'
import NullifyBoosterModal from './NullifyBoosterModal'
import RegistrationConfirmationModal from './RegistrationConfirmationModal'
import RegistrationSuccessModal from './RegistrationSuccessModal'
import TxFailureModal from './TxFailureModal'
import TxSuccessModal from './TxSuccessModal'
import TxWaitModal from './TxWaitModal'

export default function Modals() {
  const modalState = useStore($modalState)

  let modal = null
  switch (modalState) {
    case ModalState.ConnectWallet:
      modal = <ConnectWalletModal />
      break
    case ModalState.ConnectWalletSuccess:
      modal = <ConnectWalletSuccessModal />
      break
    case ModalState.TxWait:
      modal = <TxWaitModal />
      break
    case ModalState.TxSuccess:
      modal = <TxSuccessModal />
      break
    case ModalState.TxFailure:
      modal = <TxFailureModal />
      break
    case ModalState.RegistrationSuccess:
      modal = <RegistrationSuccessModal />
      break
    case ModalState.RegistrationConfirmation:
      modal = <RegistrationConfirmationModal />
      break
    case ModalState.AboutKYC:
      modal = <AboutKYCModal />
      break
    case ModalState.NullifyBoosterWarning:
      modal = <NullifyBoosterModal />
      break
  }

  return <ModalContainer>{modal}</ModalContainer>
}
