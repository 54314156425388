import { sample } from 'effector'
import {
  openRegistrationSuccessModal,
  openTxFailModal,
  openTxWaitModal,
  setRegisterPoolAddress,
} from 'models/modal'
import { getTokenBalanceFx, sendTxFx, usdc } from 'models/wallet'
import {
  refundFx,
  registerPoolParticipationFx,
  reserveFx,
  setReserveInput,
  withdrawReservedFx,
} from '.'

sample({
  source: reserveFx.doneData,
  filter: Boolean,
  target: sendTxFx,
})

sample({
  source: refundFx.doneData,
  filter: Boolean,
  target: sendTxFx,
})

sample({
  clock: [reserveFx, refundFx, withdrawReservedFx],
  target: openTxWaitModal,
})

sample({
  clock: [reserveFx, refundFx],
  fn: () => usdc,
  target: getTokenBalanceFx,
})

sample({
  source: reserveFx.done,
  filter: Boolean,
  fn: () => '',
  target: setReserveInput,
})

sample({
  clock: registerPoolParticipationFx.done,
  fn: () => '',
  target: setRegisterPoolAddress,
})

sample({
  clock: registerPoolParticipationFx.doneData,
  filter: (result) => !result.registerPoolParticipation.success,
  target: openTxFailModal,
})

sample({
  clock: registerPoolParticipationFx.doneData,
  filter: (data) => data.registerPoolParticipation.success,
  target: openRegistrationSuccessModal,
})
