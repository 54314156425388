import { createEffect, createEvent, createStore } from 'effector'
import { connection } from 'models/wallet'

export type Tx = {
  txHash: string
  retries: number
  status: 'successful' | 'failed' | 'pending'
}

export const pushTx = createEvent<Tx>()

export const $transactions = createStore<Tx[]>([])

export const getTxFx = createEffect(async (tx: Tx) => {
  return await connection.getTransaction(tx.txHash, { commitment: 'finalized' })
})
