import * as anchor from '@project-serum/anchor'
import { PublicKey } from '@solana/web3.js'

export async function findPDA(
  programId: PublicKey,
  ...anySeeds: any
): Promise<[PublicKey, number]> {
  const seeds = []
  for (const seed of anySeeds) {
    if (typeof seed === 'string') {
      seeds.push(Buffer.from(anchor.utils.bytes.utf8.encode(seed)))
    } else if (Array.isArray(seed)) {
      seeds.push(Buffer.from(seed))
    } else if (seed instanceof anchor.web3.PublicKey) {
      seeds.push(seed.toBuffer())
    } else if (seed instanceof PublicKey) {
      seeds.push(seed.toBuffer())
    } else if (seed instanceof anchor.web3.Keypair) {
      seeds.push(seed.publicKey.toBuffer())
    } else if (typeof seed === 'number') {
      seeds.push(Buffer.from([seed]))
    }
  }

  const [pda, bump] = await anchor.web3.PublicKey.findProgramAddress(
    seeds,
    programId
  )
  return [pda, bump]
}
