import { ModalHeading, TextLink } from 'components'
import { useStore } from 'effector-react'
import { ReactComponent as BackIcon } from 'icons/back.svg'
import { $kyc } from 'models/kyc'
import { openConnectWalletSuccessModal } from 'models/modal'
import { t } from 'utils'

export default function AboutKYCModal() {
  const kyc = useStore($kyc)

  const handleBackClick = () => {
    openConnectWalletSuccessModal()
  }

  return (
    <div className="relative flex flex-grow flex-col">
      <div
        className="absolute left-0 top-[0.3rem] z-30 cursor-pointer"
        onClick={handleBackClick}
      >
        <BackIcon className="h-[13px] w-[13px] fill-current text-red mm:h-3 mm:w-3 ml:h-5 ml:w-5" />
      </div>
      <ModalHeading className="text-center">
        {t('kyc_verification')}
      </ModalHeading>
      <div className="ml:max-h-auto mt-[1.77rem] max-h-[15rem] space-y-[1.77rem] overflow-y-auto font-mono text-[0.88rem] text-black">
        <div>
          <div className="mb-4 leading-none">
            Is KYC required for IDO participation?
          </div>
          <div className="text-black/75">
            As a DAO, SolanaPrime does not require users to verify their
            identity before participating in IDOs. However, some projects
            launching on our Launchpad will have different expectations and will
            require users to be KYC verified to participate in their IDO.
            Passing the KYC process once will make you eligible to participate
            in these IDOs.
          </div>
        </div>
        <div>
          <div className="mb-4 leading-none">
            How long does the KYC verification process take?
          </div>
          <div className="text-black/75">
            Depending on the jurisdiction the KYC application may be in review
            between up to 36 hours. Upon successful verification, the status of
            successful KYC will be updated on our website within 24 hours.
          </div>
        </div>
        <div>
          <div className="mb-4 leading-none">
            Why has my KYC verification failed?
          </div>
          <div className="text-black/75">
            In very rare cases, your KYC verification may fail. To minimize the
            chance of this happening, make sure that the documents you submit
            are clearly readable, not expired or physically damaged.If this
            happens, please get in contact with us by clicking on "Having issues
            with KYC verification?".
          </div>
        </div>
      </div>
      <TextLink className="mt-[1.77rem] font-sans" href={kyc.supportLink}>
        Having issues with KYC verification?
      </TextLink>
    </div>
  )
}
