import { ModalHeading, TextLink } from 'components'
import { useStore } from 'effector-react'
import { $walletName, $walletStatus, connectWalletFx } from 'models/wallet'
import { WalletName, wallets, WalletStatus } from 'models/wallet/wallets'
import { t } from 'utils'

export default function ConnectWalletButton() {
  const walletStatus = useStore($walletStatus)
  const walletName = useStore($walletName)

  const statusLabel = (name: WalletName) => {
    if (name !== walletName) {
      return ''
    }

    switch (walletStatus) {
      case WalletStatus.Pending:
        return 'Pending...'
      case WalletStatus.Error:
        return 'Failed to connect'
      default:
        return ''
    }
  }

  return (
    <div className="flex flex-grow flex-col">
      <ModalHeading>{t('connect_wallet')}</ModalHeading>
      <div className="mt-[23px] space-y-[0.66rem] mm:mt-[1.94rem] ml:mt-[1.77rem]">
        {wallets.map((wallet) => (
          <div
            key={wallet.name}
            onClick={() => connectWalletFx(wallet.name as WalletName)}
            className="flex w-full grow cursor-pointer items-center justify-between rounded-base bg-pureBlack/[0.07] p-[1.1rem] text-[0.88rem] leading-[1.2] outline-none hover:bg-pureBlack/[0.12] active:bg-pureBlack/[0.20]"
          >
            <p className="font-bold">{wallet.name}</p>
            <div className="flex items-center">
              <p
                className={`mr-2 max-w-[70px] mm:max-w-none ${
                  walletStatus === WalletStatus.Error &&
                  walletName === wallet.name
                    ? 'text-red'
                    : 'text-black/[0.75]'
                }`}
              >
                {statusLabel(wallet.name as WalletName)}
              </p>
              <div className="rounded-base bg-pureBlack/[0.12] p-1">
                <img
                  className="h-[1.1rem] w-[1.1rem]"
                  src={wallet.icon}
                  alt={wallet.name}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 text-center font-mono text-[0.77rem] text-black">
        By connecting a wallet you accept our{' '}
        <TextLink
          className="text-[0.77rem]"
          onClick={() =>
            window.open('/solanaprime.pdf', '_blank', 'noopener,noreferrer')
          }
        >
          Terms of Use
        </TextLink>
        .
      </div>
    </div>
  )
}
