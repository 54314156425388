import { BN, Idl, Program } from '@project-serum/anchor'
import {
  createAssociatedTokenAccountInstruction,
  getAccount,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import { PublicKey, SystemProgram, Transaction } from '@solana/web3.js'
import Decimal from 'decimal.js'
import { attach, combine, createEvent, restore } from 'effector'
import { $generalInfo } from 'models/app'
import { $provider, $publicKey, $wallet, connection } from 'models/wallet'
import { Stkng } from 'smart-contracts/stkng'
import idl from 'smart-contracts/stkng.json'
import { toDecimal } from 'utils/numbers'
import { findPDA } from '../wallet/helpers'
import { Staking } from './staking'

const pid = new PublicKey(process.env.REACT_APP_STAKING_CONTRACT ?? '')
export const $program = $provider.map((provider) =>
  !!provider
    ? (new Program(idl as Idl, pid, provider) as unknown as Program<Stkng>)
    : null
)

export const setUserStakeVault = createEvent<PublicKey | null>()
export const setUserRewardVault = createEvent<PublicKey | null>()
export const setWalletBalance = createEvent<string>()
export const setStakeInput = createEvent<string>()
export const setUnstakeInput = createEvent<string>()
export const flagBalanceFetched = createEvent<boolean>()
export const removeStakeIntent = createEvent()

export const $stakingInfo = $generalInfo.map((info) => info?.stakingInfo)
export const $stakingAccount = $stakingInfo.map((info) => info?.account)
export const $stakeMint = $stakingInfo.map((info) =>
  info?.stakingToken.address ? new PublicKey(info.stakingToken.address) : null
)
export const $rewardMint = $stakingInfo.map((info) =>
  info?.rewardToken.address ? new PublicKey(info.rewardToken.address) : null
)

export const $balanceFetched = restore(flagBalanceFetched, false)
export const $stakeInput = restore(setStakeInput, '')
export const $unstakeInput = restore(setUnstakeInput, '')
export const $userStakeVault = restore(setUserStakeVault, null)
export const $userRewardVault = restore(setUserRewardVault, null)
export const $stakingEssentials = combine(
  $program,
  $publicKey,
  $stakeMint,
  $rewardMint,
  $userStakeVault,
  $userRewardVault,
  $wallet,
  (
    program,
    publicKey,
    stakeMint,
    rewardMint,
    userStakeVault,
    userRewardVault,
    wallet
  ) => ({
    program,
    publicKey,
    stakeMint,
    rewardMint,
    userStakeVault,
    userRewardVault,
    wallet,
  })
)

export const $walletBalance = restore(setWalletBalance, '0')

export const $staking = combine($generalInfo, $walletBalance).map(
  ([info, balance]) => new Staking(info?.stakingInfo, balance)
)

export const addStakeFx = attach({
  source: combine($stakingEssentials, $stakeInput, $stakingInfo),
  async effect([
    {
      program,
      publicKey,
      stakeMint,
      rewardMint,
      userStakeVault,
      userRewardVault,
      wallet,
    },
    stakeInput,
    stakingInfo,
  ]) {
    if (!wallet) return
    if (!program) return
    if (!stakeMint) return
    if (!rewardMint) return
    if (!publicKey) return
    if (!userStakeVault) return
    if (!userRewardVault) return

    const tx = new Transaction()
    tx.feePayer = publicKey

    // todo: заменить эту проверку проверкой баланса
    // Проверяем есть ли у пользователя кошелек для стейк токенов
    try {
      await getAccount(connection, userStakeVault, 'confirmed')
    } catch (err) {
      // Если нет, то добавляем в тразнакцию инструкцию на создание кошелька
      tx.add(
        createAssociatedTokenAccountInstruction(
          publicKey,
          userStakeVault,
          publicKey,
          stakeMint
        )
      )
    }

    // Проверяем есть ли у пользователя кошелек для реворд токенов
    try {
      await getAccount(connection, userRewardVault, 'confirmed')
    } catch (err) {
      // Если нет, то добавляем в тразнакцию инструкцию на создание кошелька
      tx.add(
        createAssociatedTokenAccountInstruction(
          publicKey,
          userRewardVault,
          publicKey,
          rewardMint
        )
      )
    }

    const [pool] = await findPDA(pid, 'pool', stakeMint, rewardMint)
    const [user] = await findPDA(pid, 'user', publicKey, pool)

    // Проверяем есть ли у пользователя аккаунт для стейкинга в нашем контракте
    try {
      await program.account.user.fetch(user)
    } catch (err) {
      // Если нет, создаем
      const ix = await program.methods
        .createUser()
        .accounts({
          owner: publicKey,
          stakeMint,
          rewardMint,
          pool,
          user,
          userStakeVault,
          userRewardVault,
          systemProgram: SystemProgram.programId,
        })
        // @ts-ignore
        .signers([wallet])
        .instruction()

      tx.add(ix)
    }

    const [poolStakeBooster] = await findPDA(pid, 'pool_stake_booster', pool)
    const [userStakeBooster] = await findPDA(
      pid,
      'user_stake_booster',
      publicKey,
      pool
    )

    try {
      await program.account.userStakeBooster.fetch(userStakeBooster)
    } catch (err) {
      const ix = await program.methods
        .initializeUserStakeBooster()
        .accounts({
          payer: publicKey,
          stakeMint,
          rewardMint,
          pool,
          poolStakeBooster,
          user,
          userStakeBooster,
          systemProgram: SystemProgram.programId,
        })
        // @ts-ignore
        .signers([wallet])
        .instruction()

      tx.add(ix)
    }

    const [stakeVault] = await findPDA(pid, 'stake_vault', pool)
    const epoch = (await program.account.pool.fetch(pool)).currentEpoch
    const [carps] = await findPDA(pid, 'checkpoints', pool, epoch)

    const stakeAmount = new BN(
      toDecimal(stakeInput)
        .mul(10 ** (stakingInfo?.stakingToken.decimals ?? 0))
        .floor()
        .toString()
    )

    const ix = await program.methods
      .addStake(stakeAmount)
      .accounts({
        owner: publicKey,
        stakeMint,
        rewardMint,
        user,
        userStakeVault,
        pool,
        checkpointsAndRewardPerSecond: carps,
        stakeVault,
        poolStakeBooster,
        userStakeBooster,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .instruction()

    tx.add(ix)

    return tx
  },
})

export const removeStakeFx = attach({
  source: combine($stakingEssentials, $unstakeInput, $stakingInfo),
  async effect([
    {
      program,
      publicKey,
      stakeMint,
      rewardMint,
      userStakeVault,
      userRewardVault,
      wallet,
    },
    unstakeInput,
    stakingInfo,
  ]) {
    if (!wallet) return
    if (!program) return
    if (!publicKey) return
    if (!stakeMint) return
    if (!rewardMint) return
    if (!userStakeVault) return
    if (!userRewardVault) return

    const tx = new Transaction()
    tx.feePayer = publicKey

    const [pool] = await findPDA(pid, 'pool', stakeMint, rewardMint)
    const [user] = await findPDA(pid, 'user', publicKey, pool)
    const [stakeVault] = await findPDA(pid, 'stake_vault', pool)
    const epoch = (await program.account.pool.fetch(pool)).currentEpoch
    const [carps] = await findPDA(pid, 'checkpoints', pool, epoch)
    const [poolStakeBooster] = await findPDA(pid, 'pool_stake_booster', pool)
    const [userStakeBooster] = await findPDA(
      pid,
      'user_stake_booster',
      publicKey,
      pool
    )

    try {
      await program.account.userStakeBooster.fetch(userStakeBooster)
    } catch (err) {
      const ix = await program.methods
        .initializeUserStakeBooster()
        .accounts({
          payer: publicKey,
          stakeMint,
          rewardMint,
          pool,
          poolStakeBooster,
          user,
          userStakeBooster,
          systemProgram: SystemProgram.programId,
        })
        // @ts-ignore
        .signers([wallet])
        .instruction()

      tx.add(ix)
    }

    const unstakeAmount = new BN(
      toDecimal(unstakeInput)
        .mul(10 ** (stakingInfo?.stakingToken.decimals ?? 0))
        .floor()
        .toString()
    )
    const maxFeeRatio = new BN(500) // todo: fetch from api when backend ready
    const ix = await program.methods
      .removeStake(unstakeAmount, maxFeeRatio)
      .accounts({
        owner: publicKey,
        stakeMint,
        rewardMint,
        user,
        userStakeVault,
        pool,
        checkpointsAndRewardPerSecond: carps,
        stakeVault,
        poolStakeBooster,
        userStakeBooster,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .instruction()

    tx.add(ix)

    return tx
  },
})

export const claimRewardFx = attach({
  source: $stakingEssentials,
  async effect({
    program,
    publicKey,
    stakeMint,
    rewardMint,
    userStakeVault,
    userRewardVault,
    wallet,
  }) {
    if (!wallet) return
    if (!program) return
    if (!publicKey) return
    if (!stakeMint) return
    if (!rewardMint) return
    if (!userStakeVault) return
    if (!userRewardVault) return

    const tx = new Transaction()
    tx.feePayer = publicKey

    const [pool] = await findPDA(pid, 'pool', stakeMint, rewardMint)
    const [user] = await findPDA(pid, 'user', publicKey, pool)
    const [rewardVault] = await findPDA(pid, 'reward_vault', pool)
    const epoch = (await program.account.pool.fetch(pool)).currentEpoch
    const [carps] = await findPDA(pid, 'checkpoints', pool, epoch)

    const ix = await program.methods
      .claimReward()
      .accounts({
        owner: publicKey,
        user,
        userRewardVault,
        pool,
        rewardVault,
        stakeMint,
        rewardMint,
        checkpointsAndRewardPerSecond: carps,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .instruction()

    tx.add(ix)

    return tx
  },
})

export const fetchStakingBalancesFx = attach({
  source: [$program, $publicKey, $userStakeVault],
  async effect([program, publicKey, userStakeVault]) {
    if (!program) return
    if (!publicKey) return
    if (!userStakeVault) return

    const wallet = await connection.getTokenAccountBalance(userStakeVault)
    const walletBalance = new Decimal(wallet.value.amount).div(
      10 ** wallet.value.decimals
    )

    flagBalanceFetched(true)

    return walletBalance
  },
})

export const findVaultFx = attach({
  source: $publicKey,
  async effect(publicKey, mint: PublicKey) {
    if (!publicKey) return

    const vault = await getAssociatedTokenAddress(mint, publicKey, false)
    return vault
  },
})
