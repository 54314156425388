import { ReactComponent as RocketIcon } from 'icons/rocket.svg'
import { ReactComponent as MediumIcon } from 'icons/soc/medium.svg'
import { ReactComponent as TelegramIcon } from 'icons/soc/telegram.svg'
import { ReactComponent as TwitterIcon } from 'icons/soc/twitter.svg'
import { ReactComponent as DiscordIcon } from 'icons/soc/discord.svg'
import { ReactComponent as WhitepaperIcon } from 'icons/whitepaper.svg'
import React from 'react'
import { classNames } from 'utils'

type LinkProps = {
  url?: string | null
  name?: string
  icon?: 'telegram' | 'medium' | 'twitter' | 'rocket' | 'whitepaper' | 'discord'
  className?: string
}

export default function Link({ url, name, icon, className = '' }: LinkProps) {
  const iconComp = React.useMemo(() => {
    switch (icon) {
      case 'telegram':
        return <TelegramIcon />
      case 'medium':
        return <MediumIcon />
      case 'twitter':
        return <TwitterIcon />
      case 'discord':
        return <DiscordIcon />
      case 'rocket':
        return <RocketIcon />
      case 'whitepaper':
        return <WhitepaperIcon />
    }
  }, [icon])

  if (!url) {
    return null
  }
  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div
      onClick={handleClick}
      className={classNames(
        ' mb-0 flex cursor-pointer items-center rounded-base bg-blue bg-opacity-12 px-[0.66rem] py-[0.33rem] transition hover:bg-opacity-20 active:bg-blueActive active:bg-opacity-20',
        className
      )}
    >
      {iconComp
        ? React.cloneElement(iconComp, {
            className: 'w-[0.77rem] h-[0.77rem]',
          })
        : null}
      {name ? (
        <div
          className={classNames(
            'font-mono text-[0.66rem] uppercase leading-3 text-blue',
            icon ? 'ml-2' : ''
          )}
        >
          {name}
        </div>
      ) : null}
    </div>
  )
}
