import { useStore } from 'effector-react'
import { ReactComponent as CrossIcon } from 'icons/cross2.svg'
import { $modalState, closeModal, ModalState } from 'models/modal'
import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  children: ReactNode
}

const portal = document.getElementById('modal')

export default function ModalContainer({ children }: Props) {
  const showModal = useStore($modalState.map((ms) => ms !== ModalState.Closed))

  if (!portal || !showModal) {
    return null
  }

  const content = (
    <>
      <div
        className="absolute top-[23px] right-[20px] z-30 cursor-pointer ml:top-[32px] ml:right-[32px] dxs:top-[3.3rem] dxs:right-[2.8rem]"
        onClick={() => closeModal()}
      >
        <CrossIcon className="h-[13px] w-[13px] fill-current text-red mm:h-3 mm:w-3 ml:h-4 ml:w-4" />
      </div>
      <div className="relative h-full w-full p-[20px] pb-[20px] ml:p-[32px] ml:pb-[42px] dxs:p-[2.8rem] dxs:pb-[2.3rem]">
        {children}
      </div>
    </>
  )

  return createPortal(
    <>
      <div className="w-xl fixed top-1/2 left-1/2 z-40 box-border h-auto -translate-x-1/2 -translate-y-1/2 transform">
        <div className="modal relative block w-[300px] bg-yellow mm:w-[390px] ml:w-[560px] t:w-[660px] dxs:w-[36rem]">
          {content}

          <div className="relative bottom-0 hidden h-[2.9rem] w-full overflow-hidden ml:block dxs:mt-0">
            <div className="absolute h-full w-[1024px] bg-linesForm bg-cover bg-bottom bg-no-repeat ml:bg-contain dxs:w-full dxs:bg-180%" />
          </div>
        </div>
      </div>
      <div
        onClick={() => closeModal()}
        className="fixed z-[39] h-screen w-screen bg-black bg-opacity-70"
      />
    </>,
    portal
  )
}
