import { createEvent, createStore, restore } from 'effector'

export enum TxStatus {
  Empty = 'Empty',
  Failed = 'Failed',
  Pending = 'Pending',
  Success = 'Success',
}

export enum ModalState {
  TxWait,
  TxSuccess,
  TxFailure,
  Closed,
  ConnectWallet,
  ConnectWalletSuccess,
  AboutKYC,
  RegistrationSuccess,
  RegistrationConfirmation,
  NullifyBoosterWarning,
}

export const approveStaking = createEvent()
export const claimStakingReward = createEvent()

export const openConnectWalletModal = createEvent()
export const openConnectWalletSuccessModal = createEvent()
export const openAboutKYCModal = createEvent()
export const setTxHash = createEvent<string>()
export const setTxStatus = createEvent<TxStatus>()
export const openTxWaitModal = createEvent()
export const openTxSuccessModal = createEvent()
export const openTxFailModal = createEvent()
export const openRegistrationSuccessModal = createEvent()
export const openRegistrationConfirmationModal = createEvent()
export const openNullifyBoosterWarningModal = createEvent()
export const closeModal = createEvent()
export const setRegisterPoolAddress = createEvent<string>()

export const $modalState = createStore<ModalState>(ModalState.Closed)
export const $txHash = restore(setTxHash, '')
export const $txStatus = restore(setTxStatus, TxStatus.Empty)
export const $registerPoolAddress = restore(setRegisterPoolAddress, '')
