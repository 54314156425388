import { sample } from 'effector'
import {
  $modalState,
  closeModal,
  ModalState,
  openAboutKYCModal,
  openConnectWalletModal,
  openConnectWalletSuccessModal,
  openNullifyBoosterWarningModal,
  openRegistrationConfirmationModal,
  openRegistrationSuccessModal,
  openTxFailModal,
  openTxSuccessModal,
  openTxWaitModal,
  setTxHash,
} from '.'

$modalState
  .on(openConnectWalletModal, () => ModalState.ConnectWallet)
  .on(openConnectWalletSuccessModal, () => ModalState.ConnectWalletSuccess)
  .on(openAboutKYCModal, () => ModalState.AboutKYC)
  .on(openRegistrationSuccessModal, () => ModalState.RegistrationSuccess)
  .on(
    openRegistrationConfirmationModal,
    () => ModalState.RegistrationConfirmation
  )
  .on(openNullifyBoosterWarningModal, () => ModalState.NullifyBoosterWarning)
  .on(openTxSuccessModal, () => ModalState.TxSuccess)
  .on(openTxFailModal, () => ModalState.TxFailure)
  .on(openTxWaitModal, () => ModalState.TxWait)
  .on(closeModal, () => ModalState.Closed)

sample({
  clock: closeModal,
  fn: () => '',
  target: setTxHash,
})
