import * as anchor from '@project-serum/anchor'
import { BN, Idl, Program } from '@project-serum/anchor'
import {
  createAssociatedTokenAccountInstruction,
  getAccount,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import { PublicKey, SystemProgram, Transaction } from '@solana/web3.js'
import dayjs from 'dayjs'
import { attach, createEvent, restore } from 'effector'
import { PoolStatus } from 'gql'
import find from 'lodash/find'
import { $generalInfo, $session } from 'models/app'
import { $registerPoolAddress } from 'models/modal'
import { $provider, $publicKey, $wallet, connection } from 'models/wallet'
import { findPDA } from 'models/wallet/helpers'
import { Pool } from 'smart-contracts/pool'
import idl from 'smart-contracts/pool.json'
import { graphqlSdk } from 'gql/client'
import invariant from 'tiny-invariant'

export const setReserveInput = createEvent<string>()

const pid = new PublicKey(process.env.REACT_APP_POOL_CONTRACT ?? '')
export const $program = $provider.map((provider) =>
  !!provider
    ? (new Program(idl as Idl, pid, provider) as unknown as Program<Pool>)
    : null
)

export const $pools = $generalInfo.map((info) =>
  // note: currently backend returns list of pools in random order
  //       that's why we have to sort pools here
  (info?.pools ?? [])
    .sort((a, b) => (a.meta.name > b.meta.name ? 1 : -1))
    .sort((a, b) =>
      dayjs(a.pipeline.startTime ?? '').isBefore(b.pipeline.startTime ?? '')
        ? 1
        : -1
    )
)
export const $livePools = $pools.map((pools) =>
  pools.filter((pool) =>
    [
      PoolStatus.Registration,
      PoolStatus.Whitelisting,
      PoolStatus.Prelaunch,
      PoolStatus.InProgress,
      PoolStatus.Paused,
    ].includes(pool.poolStatus)
  )
)
export const $upcomingPools = $pools.map((pools) =>
  pools.filter((pool) => [PoolStatus.ComingSoon].includes(pool.poolStatus))
)
export const $finishedPools = $pools.map((pools) =>
  pools.filter((pool) =>
    [
      PoolStatus.SoldOut,
      PoolStatus.SuccessfullyFinished,
      PoolStatus.Failed,
    ].includes(pool.poolStatus)
  )
)

export const $reserveInput = restore(setReserveInput, '')

export const reserveFx = attach({
  source: [$program, $publicKey, $wallet, $pools],
  async effect(
    [program, publicKey, wallet, pools],
    {
      reserveAmount,
      poolAddress,
    }: {
      reserveAmount: BN
      poolAddress: string
    }
  ) {
    if (!program) return
    if (!publicKey) return
    if (!wallet) return

    const pool = find(pools, { address: poolAddress })
    if (!pool) return

    const idoAdminPublicKey = new PublicKey(pool.projectAdmin ?? '')
    const [poolsc] = await findPDA(pid, idoAdminPublicKey)
    const [userAccount] = await findPDA(pid, publicKey, poolsc)

    const tx = new Transaction()
    tx.feePayer = publicKey

    // Проверяем есть ли у пользователя аккаунт в контракте пула
    try {
      await program.account.userAccount.fetch(userAccount)
    } catch (err) {
      // Если нет, создаем
      const tier = new BN(1)
      const ix = await program.methods
        .whitelistAddress(tier)
        .accounts({
          poolsc,
          userAccount,
          user: publicKey,
          signer: publicKey,
          systemProgram: SystemProgram.programId,
        })
        // @ts-ignore
        .signers([wallet])
        .instruction()

      tx.add(ix)
    }

    const payTokenMint = new PublicKey(pool.targetToken?.address ?? '')
    const sellTokenMint = new PublicKey(pool.idoToken.address)
    const [paytokenVault] = await PublicKey.findProgramAddress(
      [
        Buffer.from(payTokenMint.toBytes()),
        Buffer.from(sellTokenMint.toBytes()),
      ],
      pid
    )

    const payFromAccount = await getAssociatedTokenAddress(
      payTokenMint,
      publicKey,
      false
    )

    console.log('reserveAmount', reserveAmount.toString())

    let ix = await program.methods
      .reserve(reserveAmount)
      .accounts({
        poolsc,
        userAccount,
        user: publicKey,
        payFromAccount,
        paytokenVault,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      })
      // @ts-ignore
      .signers([wallet])
      .instruction()

    tx.add(ix)

    return tx
  },
})

export const withdrawReservedFx = attach({
  source: [$program, $publicKey, $wallet, $pools],
  async effect([program, publicKey, wallet, pools], poolAddress: string) {
    if (!program) return
    if (!publicKey) return
    if (!wallet) return

    const pool = find(pools, { address: poolAddress })
    if (!pool) return

    const idoAdminPublicKey = new PublicKey(pool.projectAdmin ?? '')
    const [poolsc] = await findPDA(pid, idoAdminPublicKey)
    const [userAccount] = await findPDA(pid, publicKey, poolsc)

    const [authority] = await PublicKey.findProgramAddress(
      [Buffer.from(anchor.utils.bytes.utf8.encode('solana_vault_pool'))],
      pid
    )

    const sellTokenMint = new PublicKey(pool.idoToken?.address ?? '')
    const sendToAccount = await getAssociatedTokenAddress(
      sellTokenMint,
      publicKey,
      false
    )

    const tx = new Transaction()
    tx.feePayer = publicKey

    // Проверяем есть ли у пользователя кошелек для ido токенов
    try {
      await getAccount(connection, sendToAccount, 'confirmed')
    } catch (err) {
      // Если нет, то добавляем в тразнакцию инструкцию на создание кошелька
      tx.add(
        createAssociatedTokenAccountInstruction(
          publicKey,
          sendToAccount,
          publicKey,
          sellTokenMint
        )
      )
    }

    const [selltokenVault] = await PublicKey.findProgramAddress(
      [Buffer.from(sellTokenMint.toBytes())],
      program.programId
    )

    let ix = await program.methods
      .withdrawReservedTokens()
      .accounts({
        poolsc,
        userAccount,
        user: publicKey,
        sendToAccount,
        selltokenVault,
        authority,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      // @ts-ignore
      .signers([wallet])
      .instruction()

    tx.add(ix)

    return tx
  },
})

export const refundFx = attach({
  source: [$program, $publicKey, $wallet, $pools],
  async effect(
    [program, publicKey, wallet, pools],

    poolAddress: string
  ) {
    if (!program) return
    if (!publicKey) return
    if (!wallet) return

    const pool = find(pools, { address: poolAddress })
    if (!pool) return

    const idoAdminPublicKey = new PublicKey(pool.projectAdmin ?? '')
    const [poolsc] = await findPDA(pid, idoAdminPublicKey)
    const [userAccount] = await findPDA(pid, publicKey, poolsc)

    const tx = new Transaction()
    tx.feePayer = publicKey

    const payTokenMint = new PublicKey(pool.targetToken?.address ?? '')
    const sellTokenMint = new PublicKey(pool.idoToken.address)
    const [paytokenVault] = await PublicKey.findProgramAddress(
      [
        Buffer.from(payTokenMint.toBytes()),
        Buffer.from(sellTokenMint.toBytes()),
      ],
      pid
    )

    const sendToAccount = await getAssociatedTokenAddress(
      payTokenMint,
      publicKey,
      false
    )

    const [authority] = await PublicKey.findProgramAddress(
      [Buffer.from(anchor.utils.bytes.utf8.encode('solana_vault_pool'))],
      pid
    )

    let ix = await program.methods
      .refund()
      .accounts({
        poolsc,
        userAccount,
        user: publicKey,
        sendToAccount,
        paytokenVault,
        authority,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      // @ts-ignore
      .signers([wallet])
      .instruction()

    tx.add(ix)

    return tx
  },
})

export const registerPoolParticipationFx = attach({
  source: [$session, $registerPoolAddress],
  async effect([session, poolAddress]) {
    invariant(session, 'Session is required')
    return await graphqlSdk.RegisterPoolParticipation({
      input: { session, poolAddress, blockchain: 'solana' },
    })
  },
})
