type Props = {
  label: string
  children: React.ReactNode
}

export default function WalletTextEntry({ label, children }: Props) {
  return (
    <div>
      <div className="mb-[0.22rem] whitespace-nowrap font-mono text-[0.88rem] leading-none opacity-75">
        {label}
      </div>
      <div className="whitespace-nowrap text-[1.16rem] font-bold">
        {children}
      </div>
    </div>
  )
}
