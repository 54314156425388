import { Link as ExtLink } from 'components'
import { useStore } from 'effector-react'
import { ReactComponent as IconMenuIdos } from 'icons/menu-idos.svg'
import { ReactComponent as IconBurger } from 'icons/burger.svg'
import { ReactComponent as IconCross } from 'icons/cross.svg'
import { ReactComponent as IconDashboard } from 'icons/dashboard.svg'
import { ReactComponent as IconStaking } from 'icons/staking.svg'
import { $mobileMenuState, toggleMenu } from 'models/app'
import { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { classNames } from 'utils'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import { ReactComponent as Logo } from './logo.svg'

interface MenuItemProps {
  link: string
  name: string
  icon: React.ReactNode
  last?: boolean
}

function MenuItem({ link, name, icon, last = false }: MenuItemProps) {
  const handleClick = () => {
    toggleMenu()
  }

  return (
    <Link
      to={link}
      onClick={handleClick}
      className={classNames(
        'flex cursor-pointer items-center justify-center p-4 transition hover:bg-border dxs:p-0 dxs:hover:bg-black',
        last ? '' : 'dxs:mr-10'
      )}
    >
      {icon}
      <div className="ml-3 text-base text-blue hover:text-blueHover active:text-blueActive">
        {name}
      </div>
    </Link>
  )
}

export default function Header() {
  const ref = useRef(null)
  const mobileMenuOpen = useStore($mobileMenuState)
  const navigate = useNavigate()

  const items = (
    <>
      <MenuItem
        link="/"
        name="IDOs"
        icon={<IconMenuIdos className="h-5 w-5" />}
      />
      <MenuItem
        link="/staking"
        name="STAKING"
        icon={<IconStaking className="h-5 w-5" />}
      />
      <MenuItem
        link="/dashboard"
        name="SPIX POOL"
        icon={<IconDashboard className="h-5 w-5" />}
        last
      />
    </>
  )

  useOutsideClick(ref, () => {
    if (mobileMenuOpen) {
      toggleMenu()
    }
  })

  const handleLogoClick = () => {
    navigate('/')
  }

  return (
    <div
      ref={ref}
      className={`
      grid-in-header relative flex 
      h-[81px] grow dxs:mr-[2.8rem] dxs:ml-[2.8rem]
      `}
    >
      <div className="flex grow items-center justify-between border-r-0 border-border dxs:border-r">
        <div className="flex grow items-center justify-between dxs:justify-start">
          <div
            className="ml-4 cursor-pointer dxs:ml-0"
            onClick={handleLogoClick}
          >
            <Logo className="ml-0 h-auto w-[12rem] mm:h-[1.8rem] mm:w-[15.5rem] ml:ml-5 ml:w-[17.5rem] dxs:ml-0" />
          </div>
          <div className="mr-6 block mm:mr-10 dxs:hidden">
            <div className="cursor-pointer" onClick={() => toggleMenu()}>
              {mobileMenuOpen ? (
                <IconCross className="h-5 w-5 fill-current text-blue hover:text-blueHover" />
              ) : (
                <IconBurger className="h-5 w-5 fill-current text-blue hover:text-blueHover" />
              )}
            </div>
          </div>
          <div className="ml-10 hidden dxs:flex">{items}</div>
        </div>
        <div className="mr-[3.2rem] hidden items-center space-x-4 dxs:flex">
          <ExtLink
            icon="medium"
            name="medium"
            url="https://medium.com/solanaprime"
          />
          <ExtLink
            icon="twitter"
            name="twitter"
            url="https://twitter.com/solana_prime"
          />
          <ExtLink
            icon="telegram"
            name="telegram"
            url="https://t.me/SolanaPrimeOfficial"
          />
          <ExtLink
            icon="discord"
            name="discord"
            url="https://discord.gg/pAMHcwh6MX"
          />
        </div>
      </div>
      {mobileMenuOpen ? (
        <div className="absolute top-[73px] z-40 grid h-[194px] w-screen grid-cols-1 items-center divide-y divide-border border-y border-border bg-black dxs:hidden">
          {items}
        </div>
      ) : null}
    </div>
  )
}
