import Button from 'components/Button'
import ModalHeading from 'components/ModalHeading'
import { useStore } from 'effector-react'
import { registerPoolParticipationFx } from 'models/pools'
import { $stakingInfo } from 'models/staking'
import { t } from 'utils'

export default function RegistrationConfirmationModal() {
  const stakingInfo = useStore($stakingInfo)
  const stakingSymbol = stakingInfo?.stakingToken.symbol

  const handleClick = () => {
    registerPoolParticipationFx()
  }

  return (
    <div className="flex flex-grow flex-col">
      <ModalHeading>{t('modal_confirm_registration_heading')}</ModalHeading>
      <div className="my-[1.8rem] rounded-base bg-pureBlack/[0.07] p-[1.11rem] font-mono text-[0.77rem] leading-4 text-black">
        <p className="">
          Note: you can still upgrade to a higher tier by staking more{' '}
          {stakingSymbol}
          before the whitelisting stage!
        </p>
        <p className="mt-[0.5rem]">
          {stakingSymbol} must be staked until the end of the IDO, otherwise we
          do not guarantee correct whitelisting and allocation assignment.
        </p>
      </div>
      <Button className="w-full" size="l" onClick={handleClick}>
        {t('modal_confirm_registration_button')}
      </Button>
    </div>
  )
}
