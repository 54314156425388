import React from 'react'
import InfoPopover from 'components/InfoPopover'
import { ReactComponent as LockIcon } from 'icons/lock.svg'

interface Props {
  children?: React.ReactNode
  disabled?: boolean
  locked?: boolean
  onClick?: () => void
  className?: string
  secondary?: boolean
  size?: 'l' | 's'
  color?: 'red' | 'blue'
  lockedText?: string
}

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    disabled = false,
    locked = false,
    secondary = false,
    onClick = () => {},
    className = '',
    size = 's',
    color = 'blue',
    lockedText = '',
  } = props

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    onClick()
  }

  const cn = {
    base: 'transition outline-none overflow-hidden focus:outline-none uppercase whitespace-nowrap w-full leading-none rounded-base text-yellow font-bold',
    primaryBlue: 'bg-blue hover:bg-blueHover active:bg-blueActive',
    primaryRed: 'bg-red hover:bg-redHover active:bg-redActive',
    secondaryBlue:
      'bg-transparent hover:bg-blue hover:bg-opacity-10 active:bg-opacity-20 !text-blue border border-blue/25',
    secondaryRed:
      'bg-transparent hover:bg-red hover:bg-opacity-10 active:bg-opacity-20 !text-red border border-red/25',
    sizeBased:
      size === 's' ? 'text-[0.88rem] p-[0.88rem]' : 'text-[1.17rem] p-[1.1rem]',
    disabled: `disabled:bg-transparent disabled:opacity-50 ${
      color === 'red' ? 'disabled:border-red/100' : 'disabled:border-blue/100'
    }`,
    disabledBlue:
      'bg-transparent border border-blue/100 opacity-50 hover:bg-transparent hover:cursor-default',
    disabledRed:
      'bg-transparent border border-red/100 opacity-50 hover:bg-transparent hover:cursor-default',
  }

  const cn2 = {
    primary: color === 'blue' ? cn.primaryBlue : cn.primaryRed,
    secondary: color === 'blue' ? cn.secondaryBlue : cn.secondaryRed,
    disabled: color === 'blue' ? cn.disabledBlue : cn.disabledRed,
  }
  const cn3 = `${cn.base} ${disabled ? cn2.disabled : ''} ${
    secondary ? cn2.secondary : cn2.primary
  } ${cn.sizeBased} ${className}`

  if (locked) {
    return lockedText ? (
      <InfoPopover
        text={lockedText}
        className="min-w-[15rem] max-w-[20rem]"
        positions={['top', 'right', 'bottom', 'left']}
      >
        <button className={cn3} type="button" onClick={handleClick}>
          <div className="flex items-center justify-center">
            <LockIcon className="mr-2 h-4 w-4 fill-current" />
            {children}
          </div>
        </button>
      </InfoPopover>
    ) : (
      <button className={cn3} type="button" onClick={handleClick}>
        <div className="flex items-center justify-center">
          <LockIcon className="mr-2 h-4 w-4 fill-current" />
          {children}
        </div>
      </button>
    )
  }

  return (
    <button className={cn3} type="button" onClick={handleClick}>
      {children}
    </button>
  )
})

export default Button
