import { attach, createEvent, createStore, restore } from 'effector'
import { persist } from 'effector-storage/local'
import { GeneralInfoQuery, GeneralInfoWithKycQuery } from 'gql'
import { graphqlSdk } from 'gql/client'
import invariant from 'tiny-invariant'
//import mock from './mock.json'

export type GeneralInfo = GeneralInfoWithKycQuery | GeneralInfoQuery | null

export const toggleMenu = createEvent()
export const fetchGeneralInfo = createEvent()
export const setSession = createEvent<string | null>()

export const $mobileMenuState = createStore(false)
export const $generalInfo = createStore<GeneralInfo>(null)

export const $session = restore(setSession, null)

export const setIsResident = createEvent()
export const $isResident = createStore(false)
persist({ store: $isResident, key: 'isResident' })

export const fetchGeneralInfoFx = attach({
  source: $session,
  async effect(session) {
    //return mock.data as GeneralInfoQuery
    return await graphqlSdk.GeneralInfo({ session })
  },
})

export const fetchGeneralInfoWithKYCFx = attach({
  source: $session,
  async effect(session) {
    invariant(session, 'Session is required')
    //return mock.data as unknown as GeneralInfoWithKycQuery
    return await graphqlSdk.GeneralInfoWithKYC({ session })
  },
})
