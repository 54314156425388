import { useStore } from 'effector-react'
import { ReactComponent as CrossIcon } from 'icons/cross2.svg'
import { ReactComponent as Successful } from 'icons/toast-success.svg'
import { ReactComponent as Failed } from 'icons/toast-fail.svg'
// import { ReactComponent as Pending } from 'icons/toast-warning.svg'
import { $transactions } from 'models/transactions'
import toast, { Toast } from 'react-hot-toast'
import { explorerTxUrl, sleep } from 'utils'
import TextLink from './TextLink'
import { useEffect } from 'react'

interface Props {
  t: Toast
  txHash: string
}

export default function TxToast({ t, txHash }: Props) {
  const transactions = useStore($transactions)
  const tx = transactions.find((t) => t.txHash === txHash)

  useEffect(() => {
    if (tx?.status === 'successful') {
      sleep(2000).then(() => toast.dismiss(t.id))
    }
  }, [tx?.status, t])

  let icon = null
  let label = 'Transaction is pending'
  switch (tx?.status) {
    case 'successful':
      icon = <Successful className="h-5 w-5" />
      label = 'Transaction successful'
      break
    case 'failed':
      icon = <Failed className="h-5 w-5" />
      label = 'Transaction failed'
      break
    default:
      // icon = <Pending className="h-5 w-5" />
      icon = <WaitIcon />
      break
  }

  return (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } pointer-events-auto flex w-full max-w-md bg-yellow shadow-lg ring-1 ring-black ring-opacity-5`}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">{icon}</div>
          <div className="ml-3 flex-1">
            <p className="font-mono text-base font-light text-black">{label}</p>
            <p className="mt-1 font-mono text-sm font-light text-black">
              Sometimes transactions are not picked up by the blockchain, please
              monitor the transactions status.
            </p>
            <p className="mt-1 font-mono text-sm font-light text-black">
              View in explorer{' '}
              <TextLink href={explorerTxUrl(txHash)}>
                {txHash.slice(0, 4)}..
                {txHash.slice(txHash.length - 4, txHash.length)}
              </TextLink>
            </p>
          </div>
        </div>
      </div>

      <CrossIcon
        className="m-5 h-[18px] w-[18px] cursor-pointer"
        onClick={() => {
          toast.dismiss(t.id)
        }}
      />
    </div>
  )
}

function WaitIcon() {
  return (
    <div className="loader loader--style6" title="5">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="1.4rem"
        height="1.25rem"
        viewBox="0 0 24 30"
        xmlSpace="preserve"
      >
        <rect x="0" y="13" width="4" height="5" fill="#8CC8BC">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="10" y="13" width="4" height="5" fill="#8CC8BC">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="20" y="13" width="4" height="5" fill="#8CC8BC">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  )
}
