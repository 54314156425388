import { combine, sample } from 'effector'
import { openNullifyBoosterWarningModal, openTxWaitModal } from 'models/modal'
import { withdrawReservedFx } from 'models/pools'
import { $publicKey, authFx, disconnectWallet, sendTxFx } from 'models/wallet'
import { toDecimal } from 'utils/numbers'
import {
  $rewardMint,
  $stakeMint,
  $staking,
  $unstakeInput,
  addStakeFx,
  claimRewardFx,
  fetchStakingBalancesFx,
  findVaultFx,
  flagBalanceFetched,
  removeStakeFx,
  removeStakeIntent,
  setStakeInput,
  setUnstakeInput,
  setUserRewardVault,
  setUserStakeVault,
  setWalletBalance,
} from '.'

combine($publicKey, $stakeMint, $rewardMint).watch(
  ([pk, stakeMint, rewardMint]) => {
    if (!pk) return
    if (!stakeMint) return
    if (!rewardMint) return

    findVaultFx(stakeMint).then((vault) => !!vault && setUserStakeVault(vault))
    findVaultFx(rewardMint).then(
      (vault) => !!vault && setUserRewardVault(vault)
    )
  }
)

sample({
  clock: [addStakeFx, removeStakeFx, claimRewardFx],
  target: openTxWaitModal,
})

sample({
  source: addStakeFx.doneData,
  filter: Boolean,
  target: sendTxFx,
})

sample({
  source: addStakeFx.done,
  filter: Boolean,
  fn: () => '',
  target: setStakeInput,
})

sample({
  source: removeStakeFx.doneData,
  filter: Boolean,
  target: sendTxFx,
})

sample({
  source: removeStakeFx.done,
  filter: Boolean,
  fn: () => '',
  target: setUnstakeInput,
})

sample({
  source: claimRewardFx.doneData,
  filter: Boolean,
  target: sendTxFx,
})

sample({
  source: withdrawReservedFx.doneData,
  filter: Boolean,
  target: sendTxFx,
})

sample({
  source: fetchStakingBalancesFx.doneData,
  filter: Boolean,
  fn: (walletBalance) => walletBalance.toString(),
  target: setWalletBalance,
})

sample({
  clock: authFx.done,
  target: fetchStakingBalancesFx,
})

sample({
  clock: authFx.fail,
  target: disconnectWallet,
})

setInterval(fetchStakingBalancesFx, 5000)

sample({
  clock: fetchStakingBalancesFx.fail,
  filter: ({ error }) =>
    !!error.message.match('Invalid param: could not find account'),
  target: [
    setWalletBalance.prepend(() => '0'),
    flagBalanceFetched.prepend(() => true),
  ],
})

sample({
  clock: removeStakeIntent, // если пользователь хочет снять стейк
  source: combine($unstakeInput, $staking),
  filter: ([unstakeInput, staking]) =>
    // и после анстейка у него останется меньше, чем boostThreshold,
    staking.staked.decimal
      .minus(toDecimal(unstakeInput))
      .lessThan(staking.boosterThreshold) && !staking.boosterIsMature,
  target: openNullifyBoosterWarningModal, // то показываем модалку
})

sample({
  clock: removeStakeIntent,
  source: combine($unstakeInput, $staking),
  filter: ([unstakeInput, staking]) =>
    staking.staked.decimal
      .minus(toDecimal(unstakeInput))
      .greaterThanOrEqualTo(staking.boosterThreshold) ||
    staking.boosterIsMature,
  target: removeStakeFx,
})
