import Button from 'components/Button'
import ModalHeading from 'components/ModalHeading'
import { ReactComponent as CheckIcon } from 'icons/check.svg'
import { closeModal } from 'models/modal'
import { t } from 'utils'

export default function RegistrationSuccessModal() {
  const handleClick = () => {
    closeModal()
  }

  return (
    <div className="flex flex-grow flex-col">
      <ModalHeading>{t('registration_success')}</ModalHeading>
      <div className="flex justify-center">
        <div className="my-8">
          <CheckIcon width="3.5em" height="3.5em" />
        </div>
      </div>
      <Button className="w-full" size="l" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
