import React from 'react'
import { classNames } from 'utils'

interface Props {
  children: React.ReactNode
  className?: string
}

export default function ModalHeading({ children, className = '' }: Props) {
  return (
    <p
      className={classNames(
        'ds:-[1.5rem] text-[18px] font-bold uppercase leading-none text-black mm:text-[21px] ml:text-[26px] dxs:text-[1.3rem]',
        className
      )}
    >
      {children}
    </p>
  )
}
