import Decimal from 'decimal.js'
import { GeneralInfoQuery, GeneralInfoWithKycQuery, PoolStatus } from 'gql'
import { formatNumber, toDecimal } from './numbers'

export const POOL_STATUSES_MAP: { [key: string]: { name: string } } = {
  ComingSoon: { name: 'Coming Soon' },
  Registration: { name: 'Registration' },
  Whitelisting: { name: 'Whitelisting' },
  Prelaunch: { name: 'Pre-launch' },
  InProgress: { name: 'In Progress' },
  Paused: { name: 'Paused' },
  SoldOut: { name: 'Sold Out' },
  SuccessfullyFinished: { name: 'Finished' }, // частично выкупленный пул, больше софт кап
  Failed: { name: 'Failed' }, // не пройден софт кап
}

type GraphQLPool =
  | GeneralInfoQuery['pools'][number]
  | GeneralInfoWithKycQuery['pools'][number]

export class PoolProxy {
  public raw: GraphQLPool

  public dec: {
    availableAllocation: Decimal
    idoTokensBought: Decimal
    idoTokensVested: Decimal
    maxAllocation: Decimal
    targetTokenDecimals: Decimal
    usedAllocation: Decimal
  }

  public computed: {
    availableAllocation: string
    buyAllowed(amountToBuy: Decimal, walletConnected: boolean): boolean
    finished: boolean
    idoTokensBought: string
    idoTokensClaimed: string
    idoTokensPrice: string
    idoTokensSold: string
    initialMarketCap: string
    maxAllocation(walletConnected: boolean): string
    participantsCount: string
    progressLineText: string
    registeredCount: string
    roi: string
    targetTokenSymbol: string
    tokensToSell: string
    totalRaised: string
    totalRaisedShort: string
    totalTarget: string
    usedAllocation: string
    userTier: number
    vestingDescription: string
  }

  constructor(pool: GraphQLPool) {
    const { progress, idoToken } = pool
    this.raw = pool
    const meta = this.raw.meta

    // DECIMALS
    const dec: typeof this.dec = {
      availableAllocation: new Decimal(
        pool.account?.tier?.maxAllocation ?? 0
      ).sub(pool.account?.amountPaid ?? 0),

      idoTokensBought: toDecimal(pool.account?.userToReserve),

      idoTokensVested:
        pool.account?.vesting?.unlocks
          .filter((_, i) => i <= (pool.vesting?.currentPeriodIndex ?? 0))
          .map((unlock) => toDecimal(unlock))
          .reduce((current, result) => result.plus(current), new Decimal(0)) ??
        new Decimal(0),

      maxAllocation: new Decimal(pool.account?.tier?.maxAllocation ?? 0),

      targetTokenDecimals: new Decimal(pool.targetToken?.decimals ?? 0),

      usedAllocation: new Decimal(pool.account?.amountPaid ?? 0),
    }
    this.dec = dec

    let { totalTarget } = this.raw.progress
    let initialMarketCap = meta.initialMarketCap ?? '0'

    /*
     * @TODO: Hardcode only for Project Hive. remove later
     */
    if (meta.name === 'Project Hive') {
      totalTarget = '30000'
      initialMarketCap = '249000'
    }
    /*
     * @TODO: Hardcode only for stabble. remove later
     */
    if (meta.name === 'stabble') {
      totalTarget = '30000'
      initialMarketCap = '495500'
    }

    const poolPrice = pool.price !== '0' ? pool.price : undefined
    const idoTokenPrice = poolPrice || pool.meta.constantUSDTokenPrice
    const targetTokenSymbol =
      pool.targetToken?.symbol !== 'Undefined'
        ? pool.targetToken?.symbol ?? 'USDC'
        : 'USDC'

    // COMPUTED
    this.computed = {
      availableAllocation: `${formatNumber(dec.availableAllocation, {
        fractionDigits: 4,
      })} ${pool?.targetToken?.symbol ?? 'USDC'}`,

      buyAllowed(amountToBuy: Decimal, walletConnected: boolean) {
        return (
          walletConnected &&
          pool.poolStatus === PoolStatus.InProgress &&
          (pool.account?.isWhitelisted || !pool.meta.private) &&
          amountToBuy.lessThanOrEqualTo(dec.availableAllocation) &&
          amountToBuy.greaterThan(pool.minOrderSizeTargetToken) &&
          amountToBuy.greaterThan(new Decimal(10).pow(-dec.targetTokenDecimals))
        )
      },

      finished:
        pool.poolStatus === PoolStatus.SuccessfullyFinished ||
        pool.poolStatus === PoolStatus.SoldOut ||
        pool.poolStatus === PoolStatus.Failed,

      idoTokensBought: `${formatNumber(dec.idoTokensBought, {
        fractionDigits: 4,
      })} ${idoToken.symbol}`,

      idoTokensClaimed: `${formatNumber(toDecimal(pool.account?.claimAmount), {
        fractionDigits: 4,
      })} ${idoToken?.symbol ?? 'USDC'}`,

      idoTokensPrice:
        idoTokenPrice !== '0' && idoTokenPrice
          ? `${formatNumber(idoTokenPrice, { fractionDigits: 9 })} ${
              targetTokenSymbol ?? 'USDC'
            }`
          : 'TBA',

      idoTokensSold: progress.tokensSold
        ? `${formatNumber(progress.tokensSold, { fractionDigits: 4 })} ${
            idoToken.symbol
          }`
        : 'TBA',

      // initialMarketCap: pool.meta.initialMarketCap !== "0" && pool.meta.initialMarketCap
      //   ? `${formatNumber(pool.meta.initialMarketCap ?? '0', {
      //     useGrouping: true,
      //   })} ${pool.targetToken?.symbol ?? 'USDC'}`
      //   : 'TBA',

      initialMarketCap:
        initialMarketCap !== '0'
          ? `${formatNumber(initialMarketCap, {
              useGrouping: true,
            })} ${targetTokenSymbol}`
          : 'TBA',

      maxAllocation(walletConnected: boolean) {
        return walletConnected
          ? `${formatNumber(dec.maxAllocation, {
              fractionDigits: 6,
            })} ${pool?.targetToken?.symbol ?? 'USDC'}`
          : '-'
      },

      participantsCount:
        pool.statistics.participantsCount > 0
          ? formatNumber(pool.statistics.participantsCount)
          : '-',

      progressLineText: `${
        progress.tokensSold ? formatNumber(progress.tokensSold) : '0'
      } / ${
        progress.tokensToSell ? formatNumber(progress.tokensToSell) : '0'
      } ${idoToken.symbol}`,

      registeredCount:
        pool.statistics.registeredCount > 0
          ? formatNumber(pool.statistics.registeredCount)
          : '-',

      roi:
        !pool.meta.ROIAllTimeHigh || pool.meta.ROIAllTimeHigh === '0'
          ? 'TBA'
          : `${pool.meta.ROIAllTimeHigh}%`,

      targetTokenSymbol,

      tokensToSell:
        progress.tokensToSell !== '0' && progress.tokensToSell
          ? `${formatNumber(progress.tokensToSell, {
              fractionDigits: 12,
            })} ${idoToken.symbol}`
          : 'TBA',

      totalRaised: `${formatNumber(
        new Decimal(progress.tokensSold ?? 0).mul(pool.price ?? 0),
        { useGrouping: true }
      )} ${pool.targetToken?.symbol ?? 'USDC'}`,

      totalRaisedShort: `${formatNumber(
        new Decimal(progress.tokensSold ?? 0).mul(pool.price ?? 0),
        { useGrouping: true, shortSymbols: true }
      )} ${pool.targetToken?.symbol ?? 'USDC'}`,

      totalTarget:
        totalTarget !== '0' && totalTarget
          ? `${formatNumber(totalTarget)} ${targetTokenSymbol}`
          : 'TBA',

      usedAllocation: `${formatNumber(dec.usedAllocation, {
        fractionDigits: 4,
      })}/${formatNumber(dec.maxAllocation, { fractionDigits: 4 })} ${
        pool?.targetToken?.symbol ?? 'USDC'
      }`,

      userTier: 6 - (pool.account?.tier?.level ?? 0),

      vestingDescription: pool.meta.vestingDescription ?? '-',
    }
  }
}
