import Button from 'components/Button'
import ModalHeading from 'components/ModalHeading'
import { useStore } from 'effector-react'
import { $staking, removeStakeFx } from 'models/staking'
import { t } from 'utils'

export default function NullifyBoosterModal() {
  const staking = useStore($staking)

  return (
    <div className="flex flex-grow flex-col space-y-[1.8rem]">
      <ModalHeading>
        {t('modal_nullify_booster_heading', {
          symbol: staking.stakingTokenSymbol,
        })}
      </ModalHeading>
      <div className="text-center font-mono text-sm text-black">
        You will irreversibly lose your stake boost of{' '}
        <span className="font-semibold">
          {staking.stakeBoosterBalance} {staking.stakingTokenSymbol}
        </span>{' '}
        if your total stake falls below your reference stake before maturity
        date.
      </div>
      <div className="rounded-base bg-pureBlack/[0.07] p-[1.11rem] text-center font-mono text-sm text-black">
        Your participation tier and {staking.rewardTokenSymbol} earnings will
        suffer as well.
      </div>
      <div className="text-center font-mono text-sm text-black">
        You can unstake{' '}
        <span className="text-red">
          {staking.boosterSafeUnstake} {staking.stakingTokenSymbol}
        </span>{' '}
        without losing stake booster.
      </div>
      <Button className="w-full" size="l" onClick={removeStakeFx}>
        Unstake anyway
      </Button>
    </div>
  )
}
