import { classNames } from 'utils'

type StatusProps = {
  text: string
  secondary?: boolean
  className?: string
}

export default function Status({
  text,
  secondary,
  className = '',
}: StatusProps) {
  const bgColor = secondary ? 'bg-red text-red' : 'bg-blue text-blue'

  return (
    <div
      className={classNames(
        bgColor,
        'mb-0 inline-block items-center whitespace-nowrap rounded-base bg-opacity-12 px-[0.66rem] py-[0.33rem] font-mono text-[0.66rem] uppercase',
        className
      )}
    >
      {text}
    </div>
  )
}
