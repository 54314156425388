import { Dropdown } from 'components'
import Status from 'components/Status'
import dayjs from 'dayjs'
import { PoolStatus } from 'gql'
import { useNavigate } from 'react-router-dom'
import { PoolProxy } from 'utils'
import { formatUTC } from 'utils/dayjs'

interface Props {
  pool: PoolProxy
}

interface Statuses {
  [key: string]: { text: string; startText: string }
}

const STATUSES_MAP: Statuses = {
  Registration: { text: 'Registration', startText: 'Registration ends in' },
  PreLaunch: { text: 'Pre-Launch', startText: 'IDO begins in' },
  Sale: { text: 'Sale', startText: 'Sale ends in' },
  Upcoming: { text: 'Upcoming', startText: '' },
  FullyVested: { text: 'Fully Vested', startText: '' },
  Distribution: { text: 'Distribution', startText: '' },
  Failed: { text: 'Failed', startText: '' },
}

export default function ProjectCardFinished({ pool }: Props) {
  const navigate = useNavigate()
  const { brandLogo, name, subtitle } = pool.raw.meta
  const cn = {
    grid: 'grid-cols-finishedProjectsCard dxs:grid-cols-finishedProjectsCardHiddenStatus ds:grid-cols-finishedProjectsCard',
    span: 'col-span-2 ml:col-span-1',
  }

  const mapSecondaryMarkets = pool.raw.meta.secondaryMarkets.map((item) => {
    return {
      text: item.name,
      onClick: () => {
        window.open(item.url, '_blank', 'noopener,noreferrer')
      },
    }
  })

  let status: string = ''
  switch (pool.raw.poolStatus) {
    case PoolStatus.ComingSoon:
      status = 'Upcoming'
      break
    case PoolStatus.Registration:
      status = 'Registration'
      break
    case PoolStatus.Prelaunch:
    case PoolStatus.Whitelisting:
      status = 'Prelaunch'
      break
    case PoolStatus.InProgress:
      status = 'Sale'
      break
    case PoolStatus.SoldOut:
    case PoolStatus.SuccessfullyFinished:
      const ongoing = dayjs(
        pool.raw.vesting?.unlockCheckpoints[
          pool.raw.vesting.unlockCheckpoints.length - 1
        ]
      ).isAfter(dayjs(pool.raw.currentTime))
      status = ongoing ? 'Distribution' : 'FullyVested'
      break
    case PoolStatus.Failed:
      status = 'Failed'
      break
    default:
      status = ''
      break
  }

  return (
    <div
      className={`
      relative mb-[1.1rem] grid grid-cols-2 items-center gap-x-2 gap-y-4 bg-secondaryBlack
      bg-opacity-50  p-[1.1rem] hover:bg-opacity-90 ml:grid-cols-4 dxs:gap-4 dxs:px-[2.2rem] dxs:py-[1.3rem] ${cn.grid} `}
    >
      <button
        className="absolute left-0 top-0 z-0 h-full w-full"
        onClick={() => navigate(`/project/${pool.raw.address.slice(-8)}`)}
      />
      <div className="col-span-2 flex items-start ml:col-span-4 dxs:col-span-1">
        <div className="mr-[1.11rem] rounded-base border border-yellow border-opacity-25 bg-black bg-opacity-35 p-[6px] ml:p-[0.86rem]">
          <div
            className="h-[2.5rem] w-[2.5rem] bg-cover ml:h-[2.11rem] ml:w-[2.11rem]"
            style={{ backgroundImage: `url(${brandLogo})` }}
          ></div>
        </div>
        <div className="w-[71%]">
          <div className="mb-1 text-[1.66rem] font-bold leading-[1em] text-yellow">
            {name}
          </div>
          <div className="h-[2.7rem] overflow-hidden font-mono text-[0.66rem] leading-[1.43em] text-yellow ml:h-[2.5rem] t:text-[0.77rem]">
            {subtitle}
          </div>
        </div>
      </div>

      <div className="text-blue">
        <div className="font-mono text-xs opacity-75 dxs:hidden dxs:text-sm">
          ATH ROI
        </div>
        <div className="text-sm font-bold dxs:text-[1.33rem]">
          {pool.computed.roi}
        </div>
      </div>
      <div className="text-yellow">
        <div className="font-mono text-xs opacity-75 dxs:hidden dxs:text-sm">
          IDO Token price
        </div>
        <div className="text-sm font-bold dxs:text-base">
          {pool.computed.idoTokensPrice}
        </div>
      </div>
      <div className={`text-yellow ${cn.span} `}>
        <div className="font-mono text-xs opacity-75 dxs:hidden dxs:text-sm">
          Total raised
        </div>
        <div className="text-sm font-bold dxs:text-base">
          {pool.computed.totalRaisedShort}
        </div>
      </div>
      <div className="text-yellow">
        <div className="font-mono text-xs opacity-75 dxs:hidden dxs:text-sm">
          Participants
        </div>
        <div className="text-sm font-bold dxs:text-base">
          {pool.raw.statistics.participantsCount}
        </div>
      </div>
      <div className="text-yellow">
        <div className="font-mono text-xs opacity-75 dxs:hidden dxs:text-sm">
          Ended in
        </div>
        <div className="text-sm font-bold dxs:text-base">
          {formatUTC(pool.raw.pipeline.finishTime, 'MMM DD, YYYY', false)}
        </div>
      </div>
      <div className="text-yellow">
        <div className="font-mono text-xs opacity-75 dxs:hidden dxs:text-sm">
          Your allocation
        </div>
        <div className="text-sm font-bold dxs:text-base">
          {pool.computed.idoTokensBought}
        </div>
      </div>
      <div className="block text-yellow dxs:hidden ds:block">
        <div className="font-mono text-xs opacity-75 dxs:hidden dxs:text-sm">
          Status
        </div>
        <Status
          text={STATUSES_MAP[status]?.text ?? ''}
          secondary={status === 'Sale'}
        />
      </div>
      <div
        className={`ml:col-span-full dxs:col-span-1 dxs:ml-auto dxs:w-[12rem] ${cn.span}`}
      >
        {mapSecondaryMarkets.length > 0 ? (
          <Dropdown buttonClassName="w-full" items={mapSecondaryMarkets}>
            buy {pool.raw.idoToken.symbol}
          </Dropdown>
        ) : null}
      </div>
    </div>
  )
}
