import { ModalHeading } from 'components'
import Button from 'components/Button'
import { ReactComponent as CrossIcon } from 'icons/cross.svg'
import { closeModal } from 'models/modal'
import { t } from 'utils'

export default function TxFailureModal() {
  const handleClick = () => {
    closeModal()
  }

  return (
    <div className="flex flex-grow flex-col">
      <ModalHeading>{t('modal_tx_error_heading')}</ModalHeading>
      <div className="flex justify-center">
        <div className="my-8">
          <CrossIcon
            className="fill-current text-red"
            width="3.5em"
            height="3.5em"
          />
        </div>
      </div>
      <div className="mb-[1.77rem] rounded-base bg-pureBlack/[0.07] p-[1.11rem]">
        <p className="text-center font-mono text-[0.77rem] leading-4 text-black">
          Something went wrong.
        </p>
      </div>
      <Button className="w-full" size="l" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
