import get from 'lodash/get'
import { network } from 'models/wallet'
import { PoolProxy } from './pools'
import strings from './strings.json'

type ValuesObject = {
  [k: string]: any
}

export const noop = () => {}
export const tw = (classNames: TemplateStringsArray) => `${classNames}`

export const t = (k: string, value?: ValuesObject) => {
  const text = get(strings, k, k)

  if (!value) {
    return text
  }

  const regex = /(\{\{.*?\}\})|(<.*?\/>)/

  return text.split(regex).map((el: string, index: number) => {
    const isKey = (s: string) => /\{+[^}]+\}/.test(s)
    const isBr = (s: string) => /br\s*/.test(s)

    if (isKey(el)) {
      const pure = el.replace(/\{|\}/g, '')
      return get(value, pure, el)
    }

    if (isBr(el)) {
      return <br key={'br-' + index} />
    }

    return el
  })
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function truncateAddress(address: string, length: number): string {
  if (!address) {
    return ''
  }

  return `${address.substring(0, length)}...${address.substring(-length)}`
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function explorerTxUrl(hash: string) {
  return `https://explorer.solana.com/tx/${hash}${
    network !== 'mainnet-beta' ? `?cluster=${network}` : ''
  }`
}

export function explorerAddressUrl(address: string) {
  return `https://explorer.solana.com/address/${address}${
    network !== 'mainnet-beta' ? `?cluster=${network}` : ''
  }`
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function handleOpenUrl(url?: string | null): (_: any) => void {
  if (!url) return () => {}
  return () => window.open(url, '_blank', 'noopener,noreferrer')
}

export const openUrl = (url?: string) => {
  if (!url) return
  window.open(url, '_blank', 'noopener,noreferrer')
}

export { PoolProxy }
