import * as anchor from '@project-serum/anchor'
import { getAssociatedTokenAddress } from '@solana/spl-token'
import {
  Cluster,
  clusterApiUrl,
  Connection,
  PublicKey,
  Transaction,
} from '@solana/web3.js'
import Decimal from 'decimal.js'
import { attach, createEvent, restore } from 'effector'
import get from 'lodash/get'
import { $session, fetchGeneralInfo } from 'models/app'
import { openConnectWalletSuccessModal } from 'models/modal'
import { Wallet, wallet, WalletName, WalletStatus } from 'models/wallet/wallets'
import invariant from 'tiny-invariant'
import { graphqlSdk } from 'gql/client'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import dayjs from 'utils/dayjs'
import * as process from 'process'

export const usdc = new PublicKey(process.env.REACT_APP_USDC_PUBLIC_KEY ?? '')

export const connectWallet = createEvent()
export const disconnectWallet = createEvent()
export const setWallet = createEvent<Wallet>()
export const setWalletName = createEvent<WalletName>()
export const setWalletStatus = createEvent<WalletStatus>()
export const setPublicKey = createEvent<PublicKey | null>()
export const setConnected = createEvent<boolean>()
export const setTargetTokenBalance = createEvent<Decimal>()

export const $wallet = restore<Wallet>(setWallet, null)
export const $walletStatus = restore(setWalletStatus, WalletStatus.NotConnected)
export const $walletName = restore<WalletName>(setWalletName, null)
export const $targetTokenBalance = restore(
  setTargetTokenBalance,
  new Decimal(0)
)

export const $connected = restore(setConnected, false)
export const $publicKey = restore(setPublicKey, null)
export const $address = $publicKey.map((pk) => pk?.toString() ?? '')
export const $shortAddress = $address.map((address) =>
  address !== '' ? `${address.slice(0, 4)}..${address.slice(40, 44)}` : ''
)

export const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  'mainnet-beta') as Cluster

const rpcEndpoint =
  process.env.REACT_APP_SOLANA_NODE_URL ?? clusterApiUrl(network)

export const connection = new Connection(rpcEndpoint, 'confirmed')
export const $provider = $wallet.map((wallet) =>
  !!wallet
    ? new anchor.AnchorProvider(
        connection,
        wallet as unknown as anchor.Wallet,
        {
          preflightCommitment: 'confirmed',
        }
      )
    : null
)

export const connectWalletFx = attach({
  source: $session,
  async effect(session, walletName: WalletName) {
    if (!walletName) return

    const { flag, url, name, getWallet } = wallet[walletName]
    if (get(window, ['solana', flag], false) || walletName === 'Solflare') {
      const wallet = getWallet()
      setWallet(wallet)

      wallet.addListener('connect', (publicKey) => {
        setPublicKey(publicKey)
        setConnected(true)

        if (!session) {
          authFx()
            .then(() => {
              fetchGeneralInfo()
              openConnectWalletSuccessModal()
            })
            .catch(() => {
              setWalletStatus(WalletStatus.Error)
            })
        } else {
          fetchGeneralInfo()
        }
      })
      wallet.addListener('disconnect', () => setConnected(false))

      if (walletName !== 'Solflare') {
        // @ts-ignore
        window.solana?.on('accountChanged', () => disconnectWallet())
      } else {
        // @ts-ignore
        wallet.addListener('accountChanged', () => disconnectWallet())
      }

      await wallet.connect()
    } else {
      window.open(url, '_blank')
      throw new Error(`${name} wallet is not found`)
    }
  },
})

export const authFx = attach({
  source: [$address, $wallet],
  async effect([address, wallet]) {
    if (!address) return
    if (!wallet) return

    const { getAuthMessage } = await graphqlSdk.GetAuthMessage({ address })
    const message = new TextEncoder().encode(getAuthMessage)

    let sign = ''
    try {
      const signedMessage = await wallet.signMessage(message)
      sign = Buffer.from(signedMessage).toString('hex')
    } catch (err) {
      sign = address
    }

    return await graphqlSdk.Auth({
      input: { authMessage: getAuthMessage, sign },
    })
  },
})

export const sendTxFx = attach({
  source: [$wallet, $walletName],
  async effect([wallet, walletName], tx: Transaction) {
    if (!wallet) return

    const { blockhash } = await connection.getLatestBlockhash('confirmed')
    tx.recentBlockhash = blockhash

    let signed: any

    switch (walletName) {
      case 'Solflare':
        signed = await wallet.signTransaction(tx)
        break
      case 'Phantom':
        // @ts-ignore
        signed = await window.solana.signTransaction(tx)
        break
    }

    const signature = await connection.sendRawTransaction(signed.serialize())

    return signature
  },
})

export const getTokenBalanceFx = attach({
  source: $publicKey,
  async effect(publicKey, tokenMint: PublicKey) {
    invariant(publicKey, 'public key is not specified')

    const tokenAccount = await getAssociatedTokenAddress(
      tokenMint,
      publicKey,
      false
    )
    let { value } = await connection.getTokenAccountBalance(tokenAccount)

    return value.uiAmountString
  },
})

export const isSessionExpiredFx = attach({
  source: $session,
  async effect(session) {
    if (!session) return false

    const decoded = jwtDecode<JwtPayload>(session)
    const exp = decoded?.exp ?? 0
    const isExpired = dayjs.unix(exp).isBefore(dayjs())
    return isExpired
  },
})
