import { handleOpenUrl } from 'utils'

type Props = {
  tier: number
}

const discoverTiersUrl =
  'https://medium.com/solanaprime/solanaprime-tokenomics-the-power-of-the-primes-a48bb2cdebfb'

export default function TierName({ tier }: Props) {
  const noTier = tier < 1 || tier > 5
  if (noTier) {
    return (
      <div
        className="cursor-pointer whitespace-nowrap text-[1.16rem] font-bold text-blue underline hover:text-blueHover active:text-blueActive"
        onClick={handleOpenUrl(discoverTiersUrl)}
      >
        Discover tiers
      </div>
    )
  }

  const nameCn = `bg-name${tier}`
  let width = 'w-0'
  switch (tier) {
    case 1:
      width = 'w-[8rem]'
      break
    case 2:
      width = 'w-[8rem]'
      break
    case 3:
      width = 'w-[9rem]'
      break
    case 4:
      width = 'w-[7rem]'
      break
    case 5:
      width = 'w-[11rem]'
      break
  }

  return (
    <div
      className={`${nameCn} mt-2 bg-contain text-blue ${width} h-8 bg-no-repeat`}
    />
  )
}
