import Decimal from 'decimal.js'
import { GeneralInfoQuery, GeneralInfoWithKycQuery } from 'gql'
import dayjs, { formatUTC } from 'utils/dayjs'
import { formatNumber, toDecimal } from 'utils/numbers'

type StakingInfo = (GeneralInfoWithKycQuery | GeneralInfoQuery)['stakingInfo']

export class Staking {
  unstakingFeeRatio: string
  stakingTokenSymbol: string
  rewardTokenSymbol: string
  rewardAvailable: string
  rewardClaimed: Decimal
  rewardTotalEarned: Decimal
  balance: Decimal
  staked: {
    decimal: Decimal
    formatted: string
  }
  boosterUnlockTime: string
  boosterThreshold: Decimal
  boosterSafeUnstake: string
  boosterIsMature: boolean
  stakeBoosterBalance: string

  // staked + booster
  totalStaked: {
    decimal: Decimal
    formatted: string
  }

  constructor(info?: StakingInfo, balance?: string) {
    this.balance = toDecimal(balance ?? '0')
    this.unstakingFeeRatio = `${Number(info?.unstakingFeeRatio ?? 0) * 100}`
    this.stakingTokenSymbol = info?.stakingToken.symbol ?? ''
    this.rewardTokenSymbol = info?.rewardToken.symbol ?? ''
    this.rewardAvailable = info?.account?.rewardAvailable
      ? formatNumber(info?.account?.rewardAvailable ?? '', {
          fractionDigits: 4,
        })
      : ''
    this.rewardClaimed = toDecimal(
      formatNumber(info?.account?.rewardClaimed ?? '0', {
        fractionDigits: 4,
      })
    )
    this.rewardTotalEarned = toDecimal(info?.account?.rewardTotal ?? '0')
    this.staked = {
      decimal: toDecimal(info?.account?.stakingBalance ?? '0'),
      formatted: formatNumber(info?.account?.stakingBalance ?? '0', {
        fractionDigits: 4,
      }),
    }
    this.boosterUnlockTime = formatUTC(
      info?.account?.stakeBooster?.boosterUnlockTime,
      'DD MMM YYYY'
    )
    this.boosterThreshold = toDecimal(
      info?.account?.stakeBooster?.stakeBoosterThreshold ?? '0'
    )
    this.stakeBoosterBalance = formatNumber(
      info?.account?.stakeBooster?.stakeBoosterBalance ?? '0',
      {
        fractionDigits: 4,
      }
    )

    const totalStaked = this.staked.decimal.add(
      toDecimal(info?.account?.stakeBooster?.stakeBoosterBalance ?? '0')
    )
    this.totalStaked = {
      decimal: totalStaked,
      formatted: formatNumber(totalStaked, {
        fractionDigits: 4,
      }),
    }

    this.boosterSafeUnstake = formatNumber(
      Decimal.max(this.staked.decimal.minus(this.boosterThreshold), 0),
      { fractionDigits: 4 }
    )
    this.boosterIsMature = dayjs().isAfter(
      info?.account?.stakeBooster?.boosterUnlockTime ?? 0
    )
  }
}
