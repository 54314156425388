import { ModalHeading } from 'components'
import { t } from 'utils'

export default function TxWaitModal() {
  return (
    <div className="flex flex-grow flex-col">
      <ModalHeading>{t('modal_tx_wait_heading')}</ModalHeading>
      <div className="flex justify-center">
        <div className="my-8">
          <WaitIcon />
        </div>
      </div>
      <div className="rounded-base bg-pureBlack/[0.07] p-[1.11rem]">
        <p className="text-center font-mono text-[0.77rem] leading-4 text-black">
          Confirm this transaction in your wallet.
        </p>
      </div>
    </div>
  )
}

function WaitIcon() {
  return (
    <div className="loader loader--style6" title="5">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="2.8em"
        height="3.5em"
        viewBox="0 0 24 30"
        xmlSpace="preserve"
      >
        <rect x="0" y="13" width="4" height="5" fill="#8CC8BC">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="10" y="13" width="4" height="5" fill="#8CC8BC">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="20" y="13" width="4" height="5" fill="#8CC8BC">
          <animate
            attributeName="height"
            attributeType="XML"
            values="5;21;5"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="13; 5; 13"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  )
}
