import { Menu } from '@headlessui/react'
import { Button } from 'components'
import { ReactNode } from 'react'
import { ReactComponent as ChevronUpIcon } from 'resources/images/chevron-up.svg'
import { noop } from 'utils'

type Props = {
  className?: string
  buttonClassName?: string
  children: ReactNode
  items: {
    text: string
    onClick?(): void
    disabled?: boolean
  }[]
  disabled?: boolean
}

export default function Dropdown({
  children,
  items,
  className = '',
  buttonClassName = '',
}: Props) {
  return (
    <div className={`${className} font-manrope relative`}>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button as="div">
              <Button
                secondary
                className={`${buttonClassName} ${open ? '!bg-blue/10' : ''}`}
              >
                <div className="flex items-center justify-between space-x-[0.6rem]">
                  {children}
                  <ChevronUpIcon
                    className={`text-blackSecondary h-[0.6rem] w-[0.6rem] fill-current ${
                      open ? 'rotate-180' : 'rotate-0'
                    } transform cursor-pointer`}
                  />
                </div>
              </Button>
            </Menu.Button>
            {items.length > 0 ? (
              <Menu.Items className="absolute z-10 mt-[0.2rem] w-full overflow-hidden rounded-base text-black">
                {items.map(({ text, onClick = noop, disabled = false }) => (
                  <Menu.Item
                    key={text}
                    className={`cursor-pointer bg-blue p-[0.6rem] text-[0.8rem] hover:bg-opacity-90`}
                    as="div"
                    onClick={onClick}
                  >
                    {text}
                  </Menu.Item>
                ))}
              </Menu.Items>
            ) : null}
          </>
        )}
      </Menu>
    </div>
  )
}
