import { sample } from 'effector'
import { fetchGeneralInfoWithKYCFx } from 'models/app'
import { $kyc } from '.'
import { Kyc } from './kyc'

sample({
  clock: fetchGeneralInfoWithKYCFx.doneData,
  fn: ({ kycInfo }) => new Kyc(kycInfo),
  target: $kyc,
})
