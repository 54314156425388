type Props = {
  img: React.ReactNode
  children: React.ReactNode
}

export default function WalletBlock({ img, children }: Props) {
  return (
    <div className="relative rounded-b-base bg-black/10 ml:rounded-t-base">
      <div className="flex p-[0.88rem] text-black mm:p-[1.1rem]">
        <div className="flex grow items-center space-x-2">
          <div className={`relative hidden h-[90%] min-w-[40%] ml:block`}></div>
          <div className="m-0 flex grow flex-col space-y-[0.88rem]">
            {children}
          </div>
        </div>
      </div>
      {img}
    </div>
  )
}
