import Decimal from 'decimal.js'
import { combine, sample } from 'effector'
import { persist } from 'effector-storage/local'
import {
  $generalInfo,
  $session,
  fetchGeneralInfo,
  setSession,
} from 'models/app'
import {
  closeModal,
  openConnectWalletModal,
  openConnectWalletSuccessModal,
  openTxFailModal,
  openTxSuccessModal,
  openTxWaitModal,
  setTxHash,
} from 'models/modal'
import {
  $balanceFetched,
  $stakingAccount,
  $stakingInfo,
  $walletBalance,
  flagBalanceFetched,
  setWalletBalance,
} from 'models/staking'
import {
  $connected,
  $wallet,
  $walletName,
  authFx,
  connectWallet,
  connectWalletFx,
  disconnectWallet,
  getTokenBalanceFx,
  isSessionExpiredFx,
  sendTxFx,
  setConnected,
  setPublicKey,
  setTargetTokenBalance,
  setWallet,
  setWalletName,
  setWalletStatus,
  usdc,
} from '.'
import { WalletStatus } from './wallets'

persist({ store: $session, key: 'session' })
persist({ store: $walletName, key: 'walletName' })
persist({ store: $walletBalance, key: 'walletBalance' })
persist({ store: $balanceFetched, key: 'balanceFetched' })

connectWalletFx.watch((name) => {
  setWalletName(name)
  setWalletStatus(WalletStatus.Pending)
})
connectWalletFx.done.watch(({ params }) => {
  setWalletName(params)
})
connectWalletFx.fail.watch(({ params }) => {
  setWalletName(params)
  setWalletStatus(WalletStatus.Error)
})

sendTxFx.watch(() => openTxWaitModal())
sendTxFx.done.watch(() => openTxSuccessModal())
sendTxFx.fail.watch((err) => {
  console.log(err)
  openTxFailModal()
})

sample({
  source: sendTxFx.doneData,
  filter: Boolean,
  target: setTxHash,
})

sample({
  clock: connectWallet,
  source: $connected,
  filter: (connected) => !connected,
  target: openConnectWalletModal,
})

sample({
  clock: connectWallet,
  source: combine($connected, $session),
  filter: ([connected, session]) => connected && session !== '',
  target: openConnectWalletSuccessModal,
})

// sample({
//   clock: $wallet,
//   source: combine($connected, $session),
//   filter: ([connected, session]) => connected && !session,
//   fn: () => null,
//   target: authFx,
// })

sample({
  clock: authFx.doneData,
  filter: Boolean,
  fn: ({ auth }) => auth.session,
  target: setSession,
})

sample({
  clock: $session,
  filter: Boolean,
  fn: () => WalletStatus.Connected,
  target: setWalletStatus,
})

$wallet.on(disconnectWallet, (wallet) => {
  wallet?.disconnect()
})

disconnectWallet.watch(() => {
  $wallet.getState()?.removeAllListeners()

  setWalletName(null)
  setWalletStatus(WalletStatus.NotConnected)
  setWallet(null)
  setPublicKey(null)
  setConnected(false)
  setSession('')
  setWalletBalance('0')
  flagBalanceFetched(false)
  $generalInfo.reset()
  $stakingInfo.reset()
  $stakingAccount.reset()

  localStorage.clear()

  closeModal()
  fetchGeneralInfo()
})

getTokenBalanceFx(usdc)
setInterval(() => getTokenBalanceFx(usdc), 5000)

sample({
  clock: getTokenBalanceFx.doneData,
  fn: (balance) => new Decimal(balance ?? 0),
  target: setTargetTokenBalance,
})

const walletName = $walletName.getState()
if (walletName) {
  setTimeout(() => connectWalletFx(walletName), 250)
}

setInterval(isSessionExpiredFx, 1000)
sample({
  source: isSessionExpiredFx,
  filter: Boolean,
  target: disconnectWallet,
})
